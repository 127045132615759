import React, {ReactElement, useEffect} from "react";
import {Alert, AlertTitle, Box, Collapse, IconButton, Typography, Zoom} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';

export function Popup(props: any) : ReactElement {
    const type: "error" | "success" | "info" | "warning" = props.type as "error" | "success" | "info" | "warning" | undefined ?? 'error';
    const title: string = props.title as string;
    const message: any = props.message as any;
    const [open, setOpen] = React.useState(props.open as boolean ?? true);
    const theme = useTheme();

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setOpen(false);
        }, 5000);
        return () => clearTimeout(timeoutId);
    }, []);

    return (<>
        <Zoom key={'popup'} in={open} timeout={transitionDuration} style={{transitionDelay: `${transitionDuration.exit}ms`}}
              unmountOnExit>
            <Box sx={{position: 'absolute', bottom: 10, right: 10, 'z-index': 99999}}>
                <Collapse in={open}>
                    <Alert severity={type} action={
                        <IconButton
                            aria-label="Закрыть"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit"/>
                        </IconButton>
                    }>
                        <AlertTitle>{title}</AlertTitle>
                        {message.split("\n").map((m: string) =>
                            <Typography variant={'caption'} component={'div'}>{m}</Typography>
                        )}
                    </Alert>
                </Collapse>
            </Box>
        </Zoom>
    </>)
}