import React, {ReactElement, useEffect, useState} from "react";
import {
    Autocomplete, Button,
    Grid2, IconButton,
    InputAdornment, Stack,
    TextField, Toolbar, Typography
} from "@mui/material";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {MenuPart} from "./MenuPart";
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ruRU } from '@mui/x-data-grid/locales';
import {PostType} from "../../type/PostType";
import {REST} from "../../REST";
import {Popup} from "../../Popup";

const columns: GridColDef[] = [
    { field: 'name', headerName: 'Название', flex: 0.1,
        renderHeader: (header) =>
            <Typography variant={'button'} width={header.colDef.computedWidth} sx={{fontWeight: 'bold'}} align={'center'}>{header.colDef.headerName}</Typography>,},
    { field: 'description', headerName: 'Описание', flex: 0.5,
        renderHeader: (header) =>
            <Typography variant={'button'} width={header.colDef.computedWidth} sx={{fontWeight: 'bold'}} align={'center'}>{header.colDef.headerName}</Typography>,},
    { field: 'actions', headerName: 'Действия', flex: 0.1, type: 'actions',
        renderHeader: (header) =>
            <Typography variant={'button'} width={header.colDef.computedWidth} sx={{fontWeight: 'bold'}} align={'center'}>{header.colDef.headerName}</Typography>,
        renderCell: (cell) =>
            <Stack direction={'row'} spacing={1} alignItems="center" justifyContent="center" height={'100%'}>
                <Button variant={'contained'} color={'primary'}><EditIcon/></Button>
                <Button variant={'contained'} color={'error'}><DeleteIcon/></Button>
            </Stack>
    },
];

const paginationModel = { page: 0, pageSize: 5 };

export function PostAdminPage(props: any): ReactElement {
    const [popup, setPopup] = useState<{
        type?: "error" | "success" | "info" | "warning" | undefined,
        title: string,
        message: string
    }>();
    const [posts, setPosts] = useState<PostType[]>();

    useEffect(() => {
        REST.getPost().then((p) => {
            setPosts(p);
        }).catch((reason) => {
            setPopup({title: 'Ошибка получения данных', message: reason.stack});
        })
    }, [])

    return (<>
        <MenuPart selected={'menuPost'} title={'Должности'}/>
        <Grid2
            container
            spacing={0}
            direction="column"
            justifyContent="center"
            sx={{minHeight: '100vh', minWidth: '100vw'}}
        >
            <Toolbar/>
            <Grid2 container spacing={2} direction="row" alignItems="center" justifyContent="center">
                <Grid2 size={{xs: 12, sm: 8}}>
                    <Autocomplete
                        sx={{flexGrow: 1}}
                        id="search"
                        freeSolo
                        options={[]}
                        renderInput={(params) =>
                            <TextField {...params} label="Поиск должности"
                                       slotProps={{
                                           input: {
                                               endAdornment: <InputAdornment
                                                   position="end"><IconButton><SearchIcon/></IconButton></InputAdornment>,
                                           },
                                       }}
                            />}
                    />
                </Grid2>
                <Button sx={{flexGrow: 0}} startIcon={<AddIcon/>} variant="contained" color={'success'}>Добавить
                    должность</Button>
            </Grid2>

            <DataGrid
                rows={posts}
                columns={columns}
                initialState={{pagination: {paginationModel}}}
                pageSizeOptions={[5, 10, 25, 50]}
                checkboxSelection
                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                sx={{border: 0}}
            />

        </Grid2>
        {popup ? <Popup title={popup?.title} message={popup?.message} open={true}/> : ''}
    </>)
}
