import React, {ReactElement} from "react";
import {Grid2} from "@mui/material";
import {MenuPart} from "./MenuPart";


export function CompanyAdminPage(props: any): ReactElement {
    return (<>
        <Grid2
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{minHeight: '100vh'}}
        >

            <MenuPart />

        </Grid2>
    </>)
}