import React, {ReactElement} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import IndexPage from "./page/IndexPage";
import LoginPage from "./page/LoginPage";
import {CompanyAdminPage} from "./page/company/CompanyAdminPage";
import RegisterPage from "./page/RegisterPage";
import {EmployeeAdminPage} from "./page/company/EmployeeAdminPage";
import {PostAdminPage} from "./page/company/PostAdminPage";

export default function App() : ReactElement {
  return (<>
    <BrowserRouter>
      <Routes>
        <Route path={"/"} element={<IndexPage/>}/>
        <Route path={"/login"} element={<LoginPage/>}/>
        <Route path={"/register"} element={<RegisterPage/>}/>

        <Route path={"/company"} element={<CompanyAdminPage/>}/>
        <Route path={"/company/employee"} element={<EmployeeAdminPage/>}/>
        <Route path={"/company/post"} element={<PostAdminPage/>}/>

        <Route path={"*"} element={<Navigate to={"/"}/>}/>
      </Routes>
    </BrowserRouter>
  </>);
}
