import {jwtDecode} from 'jwt-decode';
//import {saveAs} from "file-saver";
import {JWT} from "./type/JWT";
import {PostType} from "./type/PostType";

export class REST {
    public static BASE: String = process.env.REACT_APP_BASE ?? "";

    public static login(username: string, password: string): Promise<JWT> {
        return fetch(this.BASE + "/api/auth", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({username: username, password: password})
        })
            .then((response) => {
                if (response.status === 401) {
                    return response.json()
                        .then((e) => {
                            throw new Error(e['message'])
                        })
                        .catch((e: Error) => {
                            throw new Error(e.message)
                        });
                }
                return response.json();
            })
            .then((token) => {
                const jwt = jwtDecode<JWT>(token?.access_token);
                sessionStorage.setItem("me", JSON.stringify(jwt.user));
                sessionStorage.setItem("jwt", token?.access_token);
                //localStorage.setItem('token', token);
                return jwt;
            });
    }

    public static logout(): void {
        fetch(this.BASE + "/api/logout", {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .catch((error) => {
                console.error(error);
                return [];
            })
            .finally(() => {
                sessionStorage.removeItem("me");
                sessionStorage.removeItem("jwt");
                window.location.href = '/login';
            });
    }

    public static sendCode(phone: string): void {
        fetch(this.BASE + "/api/register/code?phone=" + phone, {method: "GET"});
    }

    public static register(phone: string, code: string): Promise<any> {
        return fetch(this.BASE + "/api/register", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({phone: phone, code: code})
        })
            .then((response) => {
                return response.json();
            })
            .then((data: Promise<any>) => {
                return data;
            });
    }

    public static getPost(): Promise<PostType[]> {
        return fetch(this.BASE + "/api/post", {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt"),
            }
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/login';
                }
                return response.json();
            })
            .then((data: Promise<PostType[]>) => {
                return data;
            });
    }

    /*
    public static setRequestStatus(id: bigint, status: string): Promise<RequestType> {
        return fetch(this.BASE + "/api/request/status", {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify({id: id, status: status})
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<RequestType>) => {
                return data;
            });
    }

public static download(f: FileType): void {
        fetch(this.BASE + "/api/file/download?fid=" + f.id, {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.blob();
            })
            .then((blob) => {
                saveAs(blob, f.name);
            });
    }


     */
}