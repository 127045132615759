import React, {ChangeEvent, ReactElement, useRef, useState} from "react";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent, CardHeader, Checkbox,
    Divider,
    FormControl, FormControlLabel,
    Grid2, InputAdornment, InputLabel,
    OutlinedInput, Typography
} from "@mui/material";
import {IMaskInput} from "react-imask";
import {REST} from "../REST";
import {Normalizer} from "../utils/Normalizer";

export default function RegisterPage(props: any): ReactElement {
    const [codeDisabled, setCodeDisabled] = useState<boolean>(true);
    const [registerDisabled, setRegisterDisabled] = useState<boolean>(true);
    const phone = useRef<HTMLInputElement>();
    const [codeSent, setCodeSent] = useState<boolean>(false);

    const [currentPhone, setCurrentPhone] = useState<string | null>(null);
    const [currentCode, setCurrentCode] = useState<string>('');
    const [currentAccept, setCurrentAccept] = useState<boolean>(false);

    const sendCode = () => {
        REST.sendCode(currentPhone!);

        setCodeDisabled(true);
        setRegisterDisabled(true);
        setCodeSent(true);
    }

    const register = () => {
        REST.register(currentPhone!, currentCode).then((p) => {

        })
    }

    const handleOnChangePhone = (event: ChangeEvent<HTMLInputElement>) => {
        setCodeSent(false);
        if (/^[1-9]{1}[0-9]{9}$/.test(Normalizer.phone(event.target.value))) {
            setCodeDisabled(false);
            setCurrentPhone(event.target.value);
        } else {
            setCodeDisabled(true);
            setCurrentPhone(null);
            setRegisterDisabled(true);
        }
    }
    const handleOnChangeCode = (event: ChangeEvent<HTMLInputElement>) => {
        setCurrentCode(event.target.value);
        if((currentPhone?.length ?? 0) > 0
            && event.target.value.length > 0
            && currentAccept) {
            setRegisterDisabled(false);
        } else {
            setRegisterDisabled(true);
        }
    }
    const handleOnChangeAccept = (event: ChangeEvent<HTMLInputElement>) => {
        setCurrentAccept(event.target.checked);
        if((currentPhone?.length ?? 0) > 0
            && currentCode.length > 0
            && event.target.checked) {
            setCurrentAccept(event.target.checked);
            setRegisterDisabled(false);
        } else {
            setRegisterDisabled(true);
        }
    }

    return (<>
        <Grid2
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{minHeight: '100vh', bgcolor: 'primary.main'}}
        >
            <Grid2 size={{sm: 2, md: 2, lg: 2, xl: 2, xs: 11}}>

                <Card variant={"outlined"}>
                    <CardHeader title={
                        <Typography component={"h5"} variant={"h5"}
                                    sx={{
                                        textAlign: "center",
                                        fontFamily: "Playwrite CU"
                                    }}>{process.env.REACT_APP_TITLE}</Typography>
                    }
                    />
                    <CardContent>
                        <FormControl variant="outlined" fullWidth component={"div"} required>
                            <InputLabel htmlFor={"phone"}>Телефон</InputLabel>
                            <OutlinedInput id={"phone"} name={"phone"} label={"Телефон"} fullWidth
                                           startAdornment={<InputAdornment position="start">+7</InputAdornment>}
                                           onChange={handleOnChangePhone}
                                           inputComponent={IMaskInput}
                                           ref={phone}
                                           inputProps={{mask: '(#00) 000-0000', definitions: {'#': /[1-9]/}}}
                            />
                            <Button color={"primary"} variant={"contained"} sx={{mt: 2, flexGrow: 0}}
                                    onClick={() => sendCode()} disabled={codeDisabled}>Получить
                                код</Button>
                        </FormControl>

                        {codeSent
                            ? <FormControl sx={{mt: 2}} variant="outlined" fullWidth component={"div"} required>
                                <InputLabel htmlFor={"code"}>Код из СМС</InputLabel>
                                <OutlinedInput id={"code"} name={"code"} label={"Код из СМС"} defaultValue={''}
                                               fullWidth onChange={handleOnChangeCode}/>
                            </FormControl>
                            : ''
                        }

                        <FormControlLabel sx={{mt: 2}} required
                                          control={<Checkbox size={'small'} onChange={handleOnChangeAccept}
                                                             checked={currentAccept}/>}
                                          label="Я принимаю условия пользовательского соглашения"/>
                    </CardContent>
                    <Divider/>
                    <CardActions>
                        <Button color={"warning"} variant={"contained"}
                                sx={{ml: 1, flexGrow: 0}} disabled={registerDisabled} onClick={() => register()}>Зарегистрироваться</Button>
                        <Box sx={{flexGrow: 1}}></Box>
                        <Button color={"primary"} variant={"text"} sx={{mr: 1, flexGrow: 0}}
                                href={"/login"}>Войти</Button>
                    </CardActions>
                </Card>

            </Grid2>
        </Grid2>
    </>)
}