import React, {ReactElement} from "react";
import {
    Autocomplete, Avatar, Button,
    Grid2, IconButton,
    InputAdornment, Stack,
    TextField, Toolbar, Typography
} from "@mui/material";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {MenuPart} from "./MenuPart";
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ruRU } from '@mui/x-data-grid/locales';

const columns: GridColDef[] = [
    {
        field: 'name',
        headerName: 'Сотрудник',
        flex: 0.5,
        renderHeader: (header) =>
            <Typography variant={'button'} width={header.colDef.computedWidth} sx={{fontWeight: 'bold'}} align={'center'}>{header.colDef.headerName}</Typography>,
        renderCell: (cell) =>
            <Grid2 container spacing={1} direction="row" wrap={'nowrap'}>
                <Avatar alt={cell.value} src={cell.row.avatar}><PersonOutlineIcon/></Avatar>
                <Grid2 container direction={'column'} spacing={0}>
                    {cell.value
                        ? <Typography variant={'subtitle2'} sx={{fontWeight: 'bold'}}>{cell.value}</Typography>
                        : <Typography variant={'subtitle2'} color={'error'} sx={{fontStyle: 'italic', fontWeight: 'bold'}}>Не указано</Typography>
                    }
                    {cell.row.post
                        ? <Typography variant={'caption'}>{cell.row.post}</Typography>
                        : <Typography variant={'caption'} color={'error'} sx={{fontStyle: 'italic'}}>Не указано</Typography>
                    }
                </Grid2>
            </Grid2>
    },
    { field: 'post', headerName: 'Должность', flex: 0.1,
        renderHeader: (header) =>
            <Typography variant={'button'} width={header.colDef.computedWidth} sx={{fontWeight: 'bold'}} align={'center'}>{header.colDef.headerName}</Typography>,},
    { field: 'age', headerName: 'Возраст', type: 'number', flex: 0.1,
        renderHeader: (header) =>
            <Typography variant={'button'} width={header.colDef.computedWidth} sx={{fontWeight: 'bold'}} align={'center'}>{header.colDef.headerName}</Typography>,},
    { field: 'actions', headerName: 'Действия', flex: 0.1, type: 'actions',
        renderHeader: (header) =>
            <Typography variant={'button'} width={header.colDef.computedWidth} sx={{fontWeight: 'bold'}} align={'center'}>{header.colDef.headerName}</Typography>,
        renderCell: (cell) =>
            <Stack direction={'row'} spacing={1} alignItems="center" justifyContent="center" height={'100%'}>
                <Button variant={'contained'} color={'primary'}><EditIcon/></Button>
                <Button variant={'contained'} color={'error'}><DeleteIcon/></Button>
            </Stack>},
    /*{
        field: 'fullName',
        headerName: 'ФИО',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 160,
        valueGetter: (value, row) => `${row.name || ''} ${row.post || ''}`,
    },*/
];

const rows = [
    { id: 1, post: 'Директор', name: 'Иванов Иван Иванович', age: 35, avatar: 'https://mui.com/static/images/avatar/2.jpg' },
    { id: 2, post: null, name: 'Петров Сергей Владимирович', age: 42, avatar: 'https://mui.com/static/images/avatar/2.jpg' },
    { id: 3, post: 'Уборщик', name: 'Сергеева Марина Николаевна', age: 45, avatar: 'https://mui.com/static/images/avatar/2.jpg' },
    { id: 4, post: 'Менеджер', name: 'Воронов Игорь Сергеевич', age: 16, avatar: null },
    { id: 5, post: 'Уборщик', name: 'Сидоров Николай Семенович', age: null, avatar: null },
    { id: 6, post: 'Менеджер', name: null, age: 150, avatar: 'https://mui.com/static/images/avatar/2.jpg' },
    { id: 7, post: 'Уборщик', name: 'Попов Александр Михайлович', age: 44, avatar: 'https://mui.com/static/images/avatar/2.jpg' },
    { id: 8, post: 'Менеджер', name: 'Михайлов Петр Петрович', age: 36, avatar: null },
    { id: 9, post: 'Менеджер', name: 'Курочкина Анна Ивановна', age: 65, avatar: null },
];

const paginationModel = { page: 0, pageSize: 5 };

export function EmployeeAdminPage(props: any): ReactElement {

    return (<>
        <MenuPart selected={'menuEmployee'} title={'Сотрудники'}/>
        <Grid2
            container
            spacing={0}
            direction="column"
            justifyContent="center"
            sx={{minHeight: '100vh', minWidth: '100vw'}}
        >
            <Toolbar/>
            <Grid2 container spacing={2} direction="row" alignItems="center" justifyContent="center">
                <Grid2 size={{xs: 12, sm: 8}}>
                    <Autocomplete
                        sx={{flexGrow: 1}}
                        id="search"
                        freeSolo
                        options={[]}
                        renderInput={(params) =>
                            <TextField {...params} label="Поиск сотрудников по имени или должности"
                                       slotProps={{
                                           input: {
                                               endAdornment: <InputAdornment
                                                   position="end"><IconButton><SearchIcon/></IconButton></InputAdornment>,
                                           },
                                       }}
                            />}
                    />
                </Grid2>
                <Button sx={{flexGrow: 0}} startIcon={<AddIcon/>} variant="contained" color={'success'}>Добавить сотрудника</Button>
            </Grid2>

            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{pagination: {paginationModel}}}
                pageSizeOptions={[5, 10, 25, 50]}
                checkboxSelection
                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                sx={{border: 0}}
            />

        </Grid2>
    </>)
}
